<template>
    <div>
        <a-alert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12">
                <FilterRegional
                    style="width:100%;"
                    v-model:value="state.params.regional"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <FilterProvince
                    style="width:100%;"
                    v-model:region="state.params.regional"
                    v-model:value="state.params.provinsi"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <FilterArea
                    style="width:100%;"
                    v-model:region="state.params.regional"
                    v-model:provinsi="state.params.provinsi"
                    v-model:value="state.params.area"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <FilterAtbusinessCustomerCurah
                    style="width:100%;"
                    v-model:value="state.params.customer"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <FilterAtbusinessShipto
                    style="width:100%;"
                    v-model:value="state.params.shipto"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <DownloadExcel
                    :url="state.endpoint"
                    :params="state.params"
                    namefile="Laporan-Ship-To-Customer"
                    @errors="errorMessage"/>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <TableStandard
                v-if="!state.isFetching"
                :url="state.endpoint"
                :columns="state.columns"
                :params="queryParams()"
                @errors="errorMessage"/>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, nextTick } from 'vue'
import {
    FilterRegional,
    FilterProvince,
    FilterArea,
} from '@/components/filter'
import FilterAtbusinessShipto from '@/components/filter/FilterAtbusinessShipto'
import FilterAtbusinessCustomerCurah from '@/components/filter/FilterAtbusinessCustomerCurah'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import TableStandard from '@/components/Molecules/TableStandard'
import { pickBy } from 'lodash'

export default defineComponent({
    components: {
        DownloadExcel,
        TableStandard,
        FilterRegional,
        FilterProvince,
        FilterArea,
        FilterAtbusinessCustomerCurah,
        FilterAtbusinessShipto,
    },
    setup() {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    dataIndex: 'no',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Kode Shipto',
                    dataIndex: 'kode_shipto',
                },
                {
                    title: 'Nama Shipto',
                    dataIndex: 'nama_shipto',
                },
                {
                    title: 'Kode Soldto',
                    dataIndex: 'kode_soldto',
                },
                {
                    title: 'Kode Customer Curah',
                    dataIndex: 'code_toko',
                },
                {
                    title: 'Nama Customer Curah',
                    dataIndex: 'nama_toko',
                },
                {
                    title: 'Area Customer',
                    dataIndex: 'area_customer',
                },
                {
                    title: 'Provinsi Customer',
                    dataIndex: 'provinsi',
                },
                {
                    title: 'Regional Customer',
                    dataIndex: 'regional',
                },
            ],
            endpoint:'/api/report/atb-shipto-customer',
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            isDownloading: false,
            params: {
                q: '',
                regional: [],
                provinsi: [],
                area: [],
                customer: [],
                shipto: [],
                page: 1,
                "per-page": 10,
            },
        })

        const queryParams = () => {
            let params = state.params

            return pickBy(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            nextTick().then(() => {
                state.isFetching = false
            })
        }

        return {
            fetchDataList,
            state,
            errorMessage,
            queryParams,
        }
    },
})
</script>
